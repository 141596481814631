import React from "react"

const SocialLink = ({ href, title, icon }) => {
  return (
    <>
      {
        <a
          href={href}
          target="_blank"
          rel="noopener noreferrer"
          className="mx-1 md:w-auto md:ml-3 md:mr-0"
        >
          <img src={icon} alt={title}/>
        </a>
      }
    </>
  )
}

export default SocialLink
