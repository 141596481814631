import React, {useCallback, useState} from "react"
import {Link, navigate} from "gatsby"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faBars, faSearch, faTimes} from "@fortawesome/free-solid-svg-icons"
import classnames from "classnames"
// import ShiftYourJobLogo from "../images/logo.svg"
// import ShiftYourJobTitle from "../images/title.png"
import ShiftYourJobLogoFull from "../images/shiftyourjob-beta__logo.svg"
import {formatQueryParameters, useHistoryPush, useQueryParameter} from "../utils/HistoryUtils";
import {useLocation} from "@reach/router";

const NavLink = ({children, ...props}) => (
  <Link className="pt-3 pb-6 lg:py-2 px-2 xl:px-2 2xl:px-4" {...props}>
    {children}
  </Link>
)

const Navbar = () => {
  const [showMenu, setShowMenu] = useState(false)
  const queryParameterQuery = useQueryParameter("query");
  const [query, setQuery] = useState(queryParameterQuery ?? "")
  const toggleMenu = () => setShowMenu(!showMenu)
  const location = useLocation();
  const {historySearchAdd} = useHistoryPush();


  const handleSubmit = useCallback((event) => {
      event.preventDefault();
      //if (!location.pathname.includes("/search")) {
        navigate(`/search/?${formatQueryParameters({query})}`);
      // } else {
      //   historySearchAdd({query, page: 1})
      // }
    }
    , [location.pathname, query/*, historySearchAdd*/])
  return (
    <nav className="fixed top-0 inset-x-0 z-40 bg-white font-sans h-20 shadow">
      <div className="max-w-full justify-between mx-auto xl:px-2 flex flex-col xl:flex-row h-full ">
        <div className="flex h-full w-full justify-between">
          <Link className="px-2 sm:px-3 my-2 flex flex-grow flex-none" to="/">
            <img
              src={ShiftYourJobLogoFull}
              alt="Shift your Job"
              className="self-center h-10 max-h-full mx-0"
            />

            {/* <img
              src={ShiftYourJobLogo}
              alt="Shift your Job Logo"
              className="self-center ml-2 h-auto sm:inline"
            />
            <img
              src={ShiftYourJobTitle}
              alt="Shift your Job"
              className="self-center ml-2 h-auto sm:inline"
            /> */}
          </Link>
          <div
            className={classnames(
              "flex flex-col items-center h-full px-6 py-5 xl:hidden cursor-pointer",
              showMenu
                ? "bg-primary text-secondary"
                : "bg-secondary text-primary"
            )}
            onClick={toggleMenu}
          >
            <span className="z-50 transition duration-300 ease focus:outline-none">
              <FontAwesomeIcon
                fixedWidth
                size="lg"
                icon={showMenu ? faTimes : faBars}
              />
            </span>
            <span className="text-2xs font-light">menu</span>
          </div>
        </div>

        <div
          className={classnames(
            "navbar text-lg bg-primary font-bold text-white h-screen absolute mt-20 inset-x-0 z-30 flex flex-col px-4 max-w-sm mr-0 ml-auto text-center transition",
            { open: showMenu },
            "xl:mx-auto xl:max-w-full xl:mt-0 xl:text-primary xl:relative xl:bg-white xl:flex-row xl:items-center xl:mb-0 xl:h-auto xl:flex xl:justify-between xl:pt-0"
          )}
        >
          <form
            className="flex items-center relative text-primary xl:mr-6 xl:mx-4 py-6 xl:py-2"
            onSubmit={handleSubmit}
          >
            <input
              value={query}
              className="text-xs border border-gray-300 bg-white placeholder-gray-700 rounded py-2 pr-10 pl-4 block w-full appearance-none leading-normal"
              style={{ minWidth: "260px" }}
              onChange={event => setQuery(event.target.value)}
              placeholder="Une organisation, un mot clé, ..."
            />
            <FontAwesomeIcon
              icon={faSearch}
              className="mr-4 absolute right-0 text-secondary cursor-pointer"
              onClick={handleSubmit}
            />
          </form>
          <NavLink to="/secteurs">Secteurs</NavLink>
          {/* Keep the slash behind search to avoid 302 */}
          <NavLink to="/search/">Liste&nbsp;des&nbsp;organisations</NavLink>
          {/* Keep the slash behind search to avoid 302 */}
          <NavLink to="/philosophie">Philosophie</NavLink>
          <NavLink to="/guide-utilisation">Guide&nbsp;d'utilisation</NavLink>
          {/* <NavLink to="/guide-utilisation">Mode&nbsp;d'emploi</NavLink> */}
          <NavLink
            to="/contribuer"
            className="xl:mx-4 my-5 xl:my-6 bg-secondary rounded text-sm py-3 px-8 text-primary text-center  transition duration-300 ease transition-colors hover:bg-primary hover:text-secondary"
          >
            Contribuer
          </NavLink>
        </div>
      </div>
    </nav>
  )
}

export default Navbar
