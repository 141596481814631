const slug = require('slug')
const hash = require('hash-sum')
const _ = require('lodash')
import FacetFilter from 'meilisearch'

export type organizationType = {
    nodes: {
        id: string,
        data: {
            Name: string
            Notes: string
            Type_de_Structure: string
            Effectif: string
            Categories: [{
                id: string
            }]
            Logo: {
                localFiles: [{
                    childImageSharp: {
                        fluid: any
                    }
                    ext: string
                    publicURL: string
                }]
            }
        }
    }[]
}


export type allCategoriesType = {
    nodes: {
        fields: {
            id: string,
            data: {
                Name: string,
                Notes: string,
                Levier: {
                    id: string,
                    data: {
                        Name: string
                    }
                },
                ContributionType: string,
                Parent: {
                    id: string
                }
            }
        }
    }[]
}

export type LeverType = {
    id: string
    data: {
        Name: string
        Label: {
            childMarkdownRemark: { html: string }
        }
        Prio: number
        Featured: boolean
        Picto: {
            localFiles: [{
                childImageSharp: {
                    fluid: any
                }
                ext: string
                publicURL: string
            }]
        }
    }
}
export type allLeverType = { nodes: LeverType[] }

export type CartoFilesType = {
    id: string
    data: {
        Name: string
        Notes: {
            childMarkdownRemark: { html: string }
        }
        PDF: {
            localFiles: [{
                ext: string
                publicURL: string
            }]
        }
    }
}
export type allCartoFilesType = { nodes: CartoFilesType[] }

export type DispatchFormType = {
    id: string
    data: {
        Name: string
        Form: string
        Picto: {
            localFiles: [{
                childImageSharp: {
                    fluid: any
                }
                ext: string
                publicURL: string
            }]
        }
        Prio: number
    }
}
export type allDispatchFormType = { nodes: DispatchFormType[] }


export type GlossaireType = {
    id: string
    data: {
        Name: string
        Notes: {
            childMarkdownRemark: { html: string }
        }
    }
}
export type allGlossaireType = { nodes: GlossaireType[] }


export type PartnerType = {
    id: string
    data: {
        Name: string
        Description: {
            childMarkdownRemark: { html: string }
        }
        URL: string
        Picto: {
            localFiles: [{
                childImageSharp: {
                    fluid: any
                }
                ext: string
                publicURL: string
            }]
        }
    }
}
export type allPartnerType = { nodes: PartnerType[] }


export type allOrganizationsType = {
    nodes: {
        id: string,
        data: {
            Name: string
            Categories: [{
                id: string
                data: {
                    Name: string
                }
            }]
        }
    }[]
}

export type OneOrganizationType = {
    id: string,
    data: {
        Name: string
        Linkedin: string
        Website: string
        Categories: [{
            id: string
            data: {
                Name: string
                Order: string
            }
        }]
        ActivityDescription: {
            childMarkdownRemark: { html: string }
        }
        City: [{
            id: string,
            data: {
                CityName: string
                GPSCoordinates: string
                DepartmentName: string
                RegionName: string
                Dpt2: string
            }
        }]
        Headquarter: [{
            id: string,
            data: {
                CityName: string
                GPSCoordinates: string
                DepartmentName: string
                RegionName: string
                Dpt2: string
            }
        }]
        CreationYear: string
        StructureType: string
        Tags: string
        Lever: [LeverType]
        Workforce: string
        Trainings: [{
            id: string
            data: {
                Training: string
            }
        }]
        Professions: [{
            id: string
            data: {
                Profession: string
            }
        }]
    }
}

export class CartoFiles {
    id: string
    name: string
    notes: string
    PDFURL: string

    constructor(id: string, name: string, notes: string, PDFURL: string) {
        this.id = id
        this.name = name
        this.notes = notes
        this.PDFURL = PDFURL
    }
}


export class DispatchForm {
    id: string
    name: string
    pictoURL: string
    form: string
    prio: number

    constructor(id: string, name: string, pictoURL: string = "", form: string = "", prio = 0) {
        this.id = id
        this.name = name
        this.pictoURL = pictoURL
        this.form = form
        this.prio = prio
    }

    get slug(): string {
        return `/contribuer/${slug(this.name)}`
    }
}

export class Glossaire {
    id: string
    name: string
    notes: string

    constructor(id: string, name: string, notes: string) {
        this.id = id
        this.name = name
        this.notes = notes
    }
}

export class Partner {
    id: string
    name: string
    description: string
    picto: string
    url: string

    constructor(id: string, name: string, description: string, picto: string, url: string) {
        this.id = id
        this.name = name
        this.description = description
        this.picto = picto
        this.url = url
    }
}


export class Lever {
    id: string
    name: string
    label: string
    pictoURL: string
    featured: boolean
    prio: number

    constructor(id: string, name: string, label: string, pictoURL: string, featured: boolean = false, prio: number = 0) {
        this.id = id
        this.name = name
        this.label = label
        this.pictoURL = pictoURL
        this.featured = featured
        this.prio = prio
    }
}


export type Coordinates = {
    latitude: number,
    longitude: number
}

interface OrganizationConstructorParams {
    id: string;
    name: string;
    desc?: string;
    structure?: string;
    effectif?: string;
    categories?: Category[];
    creationYear?: number;
    officeCities?: string[];
    officeCoordinates?: Coordinates[];
    headquarter?: string;
    headquarterCoordinates?: Coordinates;
    fullLocations?: string[];
    fullCoordinates?: Coordinates[];
    website?: string;
    linkedin?: string;
    tags?: string[];
    levers?: Lever[];
    trainings?: string[];
    professions?: string[];
    departments?: string[];
    regions?: string[];
}

export class Organization {
    id: string
    name: string
    categories: Category[]
    desc: string
    structure: string
    effectif: string
    creationYear: number
    logo: {
        ext: string
        publicURL: string
        childImageSharp: { fluid: any }
    }
    officeCities: string[]
    officeCoordinates: Coordinates[]
    headquarter: string
    headquarterCoordinates: Coordinates
    fullLocations: string[]
    fullCoordinates: Coordinates[]
    linkedin: URL
    website: URL
    tags: string[]
    levers: Array<Lever>
    trainings: string[]
    professions: string[]
    departments: string[]
    regions: string[]

    constructor({
                    id,
                    name,
                    desc = "",
                    structure = "",
                    effectif = "",
                    categories = [],
                    creationYear = null,
                    officeCities = [],
                    website = null,
                    linkedin = null,
                    tags = [],
                    levers = [],
                    officeCoordinates = [],
                    fullCoordinates = [],
                    fullLocations = [],
                    headquarter,
                    headquarterCoordinates = {latitude: 0, longitude: 0},
                    trainings = [],
                    professions = [],
                    departments = [],
                    regions = []
                }: OrganizationConstructorParams) {
        this.id = id
        this.name = name
        this.desc = desc
        this.structure = structure
        this.effectif = effectif?.substring(2)
        this.categories = categories
        this.creationYear = creationYear
        this.officeCities = officeCities
        this.officeCoordinates = officeCoordinates
        this.headquarter = headquarter
        this.headquarterCoordinates = headquarterCoordinates
        this.fullCoordinates = fullCoordinates
        this.fullLocations = fullLocations
        this.linkedin = this.parseUrl(linkedin)
        this.website = this.parseUrl(website)
        this.tags = tags
        this.levers = levers
        this.trainings = trainings
        this.professions = professions
        this.departments = departments
        this.regions = regions
    }

    get slug(): string {
        return `/organizations/${slug(this.name)}`
    }

    parseUrl(raw: string): URL {

        if (raw) {

            try {
                if (raw.startsWith("http")) {

                    return new URL(raw)
                } else {

                    return new URL("http://" + raw)
                }
            } catch (error) {

                console.log("Invalid URL", raw)
            }
        }
        return null
    }
}

export type MapOrganization = {
    id: string,
    name: string,
    fullCoordinates: Coordinates[],
    fullLocations: string[]
}

export const levelMap = [
    "root",
    "secteur",
    "sous-secteur",
    "famille",
    "sous-famille",
]

export class Category {

    /*
    Highlights
    CategoryPicto
    */
    parent: Category
    id: string
    originalName: string
    SEOTitle: string
    SEODesc: string
    desc: string
    keyFigures: string
    contributionType: string
    children: Array<Category>
    level: number
    hasDedicatedPage: boolean
    airtableId: string
    private _pictoURL: string
    private _totalOrgCountCached: boolean = false
    private orgIds? : Array<string>
    private _uniqueOrgsids : Array<string>

    constructor(parent: Category, id: string, name: string, desc: string,
                SEOTitle: string,
                SEODesc: string,
                keyFigures: string,
                contributionType: string,
                pictoURL: string,
                level: number = 0,
                hasDedicatedPage: boolean = false,
                airtableId: string = "",
                orgIds? : Array<string>) {

        this.parent = parent
        this.id = id
        this.SEOTitle = SEOTitle
        this.SEODesc = SEODesc

        this.originalName = name
        this.desc = desc
        this.contributionType = contributionType
        this.level = level
        this.keyFigures = keyFigures
        this._pictoURL = pictoURL
        this.hasDedicatedPage = hasDedicatedPage
        this.airtableId = airtableId
        this.orgIds = orgIds
    }

    get name(): string {
        return this.originalName
    }

    get fullname(): string {

        return this.id + "#" + this.originalName
    }

    get slug(): string {

        if (this.hasDedicatedPage) {
            return this.parent ? `${this.parent.slug}/${slug(this.name)}` : '/' + slug(this.name)
        } else {
            return this.parent ? this.parent.slug : '/' + slug(this.name)
        }
    }

    get pictoURL(): string {

        return this._pictoURL ? this._pictoURL : (this.parent ? this.parent.pictoURL : "")
    }

    ancestors(): Array<Category> {

        return (this.parent) ? [this.parent, ...this.parent?.ancestors()] : []
    }

    childrenIds(): Array<string> {

        let ids = [this.id]

        for (let child of this.children) {
            ids.push(...child.childrenIds())
        }

        return ids
    }

    siblings(): Array<Category> {

        return (this.parent) ? this.parent.children : []
    }

    get uniqueOrgs(): Array<string> {

        if (!this._totalOrgCountCached) {
            
            let ids = this.orgIds || []

            for (let child of this.children) {
                let childUniqueOrgs = child.uniqueOrgs
                ids.push(...childUniqueOrgs)
            }
            this._uniqueOrgsids = ids.filter((item, i, ar) => ar.indexOf(item) === i)
            this._totalOrgCountCached = true
        }

        return this._uniqueOrgsids
    }

    get totalOrgCount(): number {

        return this.uniqueOrgs.length
    }

    filters(): FacetFilters {

        let filters = new FacetFilters()
        filters.add(levelMap[this.level], this.fullname);
        let current = this.parent;

        while (current.level > 0) {
            filters.add(levelMap[current.level], current.fullname);
            current = current.parent
        }

        return filters;
    }
}

interface WalkCallbackType {
    (node: Category): void
}

// Build a tree based on flat Airtable data
export class Tree {

    root: Category
    flat: Array<any>

    constructor(basePath: string, flat: Array<any>) {
        this.flat = flat
            .filter(cat => cat.data.Order != null)
            .map(cat => {
                let o = cat.data.Order.slice(0, -1).split('.')
                cat.level = o.length
                cat.sort = parseInt(o.pop())
                return cat
            })

        this.root = new Category(null, '', 'Secteurs', '', '', '', '', '', null, 0, false)
        this.root.children = this.build(this.root)

        // console.log(inspect(this.root, {showHidden: false, depth: null}));
        this.flat = null
    }

    private build(parent: Category): Array<Category> {

        // ceux qui commence pareil et level + 1 et sorted
        return this.flat
            .filter(item => item.level === parent.level + 1 && item.data.Order.startsWith(parent.id))
            .sort((a, b) => a.sort - b.sort)
            .map(item => {

                let pictoURL = null
                if (item.data.CategoryPicto && item.data.CategoryPicto.localFiles.length > 0) {
                    pictoURL = item.data.CategoryPicto.localFiles[0].publicURL
                }

                let node = new Category(
                    parent,
                    item.data.Order,
                    item.data.Name,
                    item.data.Description?.childMarkdownRemark.html,
                    item.data.SEOTitle,
                    item.data.SEODesc,
                    item.data.Highlights?.childMarkdownRemark.html,
                    item.data.ContributionType,
                    pictoURL,
                    item.level,
                    item.data.HasDedicatedPage,
                    item.id,
                    item.data.Organizations?.map(o => o.id)
                )

                node.children = this.build(node)

                return node
            });
    }

    walk(callback: WalkCallbackType) {

        this._walk(this.root, callback)
    }

    private _walk(node: Category, callback: WalkCallbackType) {

        for (let child of node.children) {
            // console.log(node.slug)
            callback(child)
            this._walk(child, callback)
        }
    }

    find(id: string): Category {

        return this._find(this.root, id)
    }

    private _find(node: Category, id: string): Category {

        if (node.id === id) {
            return node
        }

        for (let child of node.children) {
            let found = this._find(child, id)
            if (found) {
                return found
            }
        }

        return null
    }
}


export type NamedEntity = { id: string, name: string };

export interface FacetDistribution {
    name: string
    distribution: NamedEntity[]
}


export class FacetFilters {

    filters: object

    constructor(filters: object = {}) {
        this.filters = filters
    }

    add(name: string, value: string) {

        if (_.has(this.filters, name) && this.filters[name].indexOf(value) == -1) {

            this.filters[name].push(value)

        } else {
            this.filters[name] = [value]
        }
    }

    remove(name: string, value: string) {

        if (_.has(this.filters, name)) {

            const index = this.filters[name].indexOf(value)
            this.filters[name].splice(index, 1);

            if (this.filters[name].length === 0) {
                delete this.filters[name];
            }
        }
    }

    removeAll(name: string) {

        if (_.has(this.filters, name)) {

            delete this.filters[name];
        }
    }

    reset() {
        this.filters = {}
    }

    hasValueSelected(name: string): boolean {

        return (_.has(this.filters, name) && this.filters[name].length > 0)
    }

    getFirstSelected(name: string): string {

        return _.has(this.filters, name) ? this.filters[name][0] : null
    }

    isSelected(name: string, value: string): boolean {

        return (_.has(this.filters, name) && this.filters[name].indexOf(value) != -1)
    }

    isEmpty(): boolean {
        return _.keys(this.filters).length == 0
    }

    flatten(): FacetFilter[] {

        const ff: FacetFilter[] = []

        for (const prop in this.filters) {

            ff.push(this.filters[prop].map(v => `${prop}:${v}`))
        }

        return ff
    }
}

export type FacetFilterType = "pill" | "checkbox" | "select";
