/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, Link } from "gatsby"

import Navbar from "./Navbar"

import FooterLink from "./FooterLink"
import SocialLink from "./SocialLink"
import rsFacebook from '../images/rs_facebook.svg';
import rsTwitter from '../images/rs_twitter.svg';
import rsLinkedin from '../images/rs_linkedin.svg';

const getSocialLinks = data => [
  {
    href: "https://www.linkedin.com/company/shiftyourjob/",
    title: "LinkedIn",
    icon : rsLinkedin
  },
  {
    href: "https://twitter.com/Shiftyourjob",
    title: "Twitter",
    icon : rsTwitter
  },
  {
    href: "https://www.facebook.com/Shift-Your-Job-105381117907490",
    title: "Facebook",
    icon : rsFacebook
  }
]

const getFooterLinks = data => [
  {
    href: "/partenaires",
    title: "Partenaires",
    external: false,
  },
  {
    href: "/glossaire",
    title: "Glossaire",
    external: false,
  },
  {
    href: "/equipe",
    title: "L'équipe",
    external: false,
  },
  {
    href: "/presse",
    title: "Presse",
    external: false,
  },
  {
    href: "/contact",
    title: "Nous contacter",
    external: false,
  }
]


const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          contactFormUrl
        }
      }
    }
  `)
  const footerLinks = getFooterLinks(data)
  const socialLinks = getSocialLinks(data)
  return (
      <div className="flex flex-col min-h-screen antialiased bg-beige">
        <Navbar />
        <main className={`flex-grow pt-20 font-serif`}>{children}</main>
        <footer className="text-xs text-primary text-center sm:text-left p-4 flex flex-col-reverse sm:flex-row sm:justify-between">
          <div className="md:flex">
            <div className="md:mb-2 mt-3 md:mt-0 sm:mr-3 text-gray-700 md:flex-grow">©SHIFT&nbsp;YOUR&nbsp;JOB&nbsp;</div>
            <Link to="/mentions-legales" className="mb-2 text-gray-700 md:flex-grow hover:underline">Mentions&nbsp;légales</Link>
          </div>
          {/* <div className="mb-2 text-gray-700 md:flex-grow">©{data.site.siteMetadata.title}&nbsp;</div> */}
          <div className="md:flex justify-center flex-wrap md:justify-end">
            <div className="flex justify-center flex-wrap text-center items-center">
                {footerLinks.map((l, i) => (
                  <FooterLink
                    key={l.title}
                    href={l.href}
                    title={l.title}
                    external={l.external}
                  />
                ))}
              </div>
              <div className="flex justify-center flex-wrap text-center mt-3 md:mt-0">
                {socialLinks.map((l, i) => (
                  <SocialLink
                    key={l.title}
                    href={l.href}
                    title={l.title}
                    icon={l.icon}
                  />
                ))}
            </div>
          </div>
        </footer>
      </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  contentClassName: PropTypes.string,
}

export default Layout
