/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
// import CookieConsent from "react-cookie-consent"

const slug = require("slug")

const SEO = ({
  description,
  lang,
  meta,
  title,
  imageUrl,
  gtmPageType,
  gtmPageName,
  gtmPageDescription,
}) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const metaTitle = title || site.siteMetadata.title
  const metaDescription = description || site.siteMetadata.description

  const metaGtmPageType = gtmPageType || "undefined"
  const metaGtmPageName = gtmPageName || "undefined"
  const metaGtmPageDescription = gtmPageDescription || "undefined"

  return (
    <div>
      <Helmet
        htmlAttributes={{
          lang,
        }}
        title={metaTitle}
        meta={[
          {
            name: "description",
            content: metaDescription,
          },
          {
            property: "og:title",
            content: metaTitle,
          },
          {
            property: "og:description",
            content: metaDescription,
          },
          {
            property: "og:type",
            content: "website",
          },
          {
            property: "og:image",
            content: "https://shiftyourjob.org/share.png",
          },
          {
            name: "twitter:card",
            content: "summary",
          },
          {
            name: "twitter:site",
            content: "@climatescape",
          },
          {
            name: "twitter:title",
            content: metaTitle,
          },
          {
            name: "twitter:description",
            content: metaDescription,
          },
          {
            name: "twitter:image",
            content: "https://shiftyourjob.org/share.png",
          },
          {
            name: "gtm:pageType",
            content: slug(metaGtmPageType.toLowerCase()),
          },
          {
            name: "gtm:pageName",
            content: slug(metaGtmPageName.toLowerCase()),
          },
          {
            name: "gtm:pageDescription",
            content: metaGtmPageDescription,
          },
        ].concat(meta)}
      ></Helmet>
    </div>
  )
}

SEO.defaultProps = {
  lang: "fr",
  meta: [],
  description: "",
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
}

export default SEO
