import React from "react"
import {Link} from "gatsby"

const FooterLink = ({ href, title, external = true }) => {
  return (
    <>
      {
      external === false ? 
        <Link to={href} className="w-full sm:w-1/2 md:w-auto md:ml-6 hover:underline">
          {title}
        </Link>
      :
        <a
          href={href}
          target={external?"_blank":"_self"}
          rel="noopener noreferrer"
          className="w-full sm:w-1/2 md:w-auto md:ml-6 hover:underline"
        >
          {title}
        </a>
      }
    </>
  )
}

export default FooterLink
