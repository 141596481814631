import React, {createContext, useCallback, useContext, useEffect, useMemo, useState} from "react";
import {FacetDistribution, MapOrganization, Organization} from "../utils/model"
import {MeiliSearch, Index, SearchParams} from "meilisearch"
import {
    useHistoryPush,
    useSearchPageMultipleQueryParameters,
    useSearchPageQueryParameters
} from "../utils/HistoryUtils";
import hash from "hash-sum";
import {isEqual} from "lodash"
// filtres iteration 3: geo
// tags ?
// Donnée: ajouter les données  textuelle manquante
// Donnée: gestion logo : pas dans meilisearch
// gestion des erreurs
// tri des resultats


type OrganizationsViewMode = "list" | "map";
type SearchFilters = {
    page: number,
    perPage: number,
    facets: Record<string, string[]>,
    query: string,
    viewMode: OrganizationsViewMode
}


type SearchContextActions = {
    addFilter: (filterName: string, filterValue: string) => void,
    removeFilter: (filterName: string, filterValue: string) => void,
    replaceFilter: (filterName: string, filterValue: string[]) => void,
    setPage: (page: number) => void,
    replaceFilters: (searchFilters: SearchFilters) => void,
    setViewMode: (viewMode: OrganizationsViewMode) => void,
}
type SearchContextData = {
    searchFilters: SearchFilters,
    searchResult: SearchResult,
    actions: SearchContextActions,
    facetsDistributions: FacetDistribution[]
}

export const SearchContext = createContext<SearchContextData | null>(null);
const uniqueFilters = ["secteur", "sous-secteur", "famille", "sous-famille"]
// Category tree : Unselect children filters

type SearchResult = {
    organizations: Organization[],
    mapOrganizations: MapOrganization[],
    count: 0,
    facetsDistribution: object,
    isLoading: boolean
}

type SearchContextProviderProps = {
    children: React.ReactNode
}

type MeilisearchFacetsDistribution = Record<string, Record<string, number>>;

function getFacetsDistribution(facetsDistribution: MeilisearchFacetsDistribution): FacetDistribution[] {
    return Object.entries(facetsDistribution)
        .map(([facetName, facetDistribution]) => ({
            name: facetName,
            distribution: Object.keys(facetDistribution).map(facetDistributionKey => ({
                id: hash(facetDistributionKey),
                name: facetDistributionKey
            }))
        }))
}


export function getCleanedQueryParameters(filterName: string) {
    switch (filterName) {
        case "famille":
            return {page: 1, "sous-famille": undefined}
        case "sous-secteur":
            return {page: 1, "famille": undefined, "sous-famille": undefined}
        case "secteur":
            return {page: 1, "famille": undefined, "sous-famille": undefined, "sous-secteur": undefined}
        case "regions":
            return {page: 1, departments: undefined, fullLocations: undefined}
        case "departments":
            return {page: 1, fullLocations: undefined}
        case "fullLocations":
            return {page: 1}
        default:
            return {};
    }
}

export function useSearchContextActions(searchFilters: SearchFilters) {
    const {
        historySearchAdd,
        historyReplaceSearch,
        historyRemoveMultipleQueryParameter,
        historySearchMultipleAdd
    } = useHistoryPush();
    const setPage = useCallback((page: number) => {
        historySearchAdd({page})
    }, [historySearchAdd]);

    return useMemo<SearchContextActions>(() => ({
        addFilter: (filterName, filterValue) => {
            const isUniqueFilter = uniqueFilters.includes(filterName);
            if (isUniqueFilter) {
                historySearchAdd({[filterName]: filterValue, ...getCleanedQueryParameters(filterName)})
                return;
            }
            historySearchMultipleAdd(filterName, filterValue)
        },
        removeFilter: (filterName, filterValue) => {
            const isUniqueFilter = uniqueFilters.includes(filterName);
            if (isUniqueFilter) {
                historySearchAdd({[filterName]: undefined, ...getCleanedQueryParameters(filterName)})
                return;
            }
            historyRemoveMultipleQueryParameter(filterName, filterValue)
        },
        replaceFilter: (filterName, filterValue) => {
            historySearchAdd({[filterName]: filterValue, page: 1})
        },
        setPage,
        replaceFilters: ({viewMode, page, perPage, facets, query}) => {
            historyReplaceSearch({viewMode, page, perPage, query, ...facets})
        },
        setViewMode: (viewMode: OrganizationsViewMode) => {
            historySearchAdd({viewMode})
        }
    }), [searchFilters, historyReplaceSearch, historySearchAdd, historyRemoveMultipleQueryParameter, setPage]);
}

export const defaultSearchFilters: SearchFilters = {
    page: 1,
    perPage: 16,
    facets: {},
    query: "",
    viewMode: "list"
}

const organizationViewModes: OrganizationsViewMode[] = ["list", "map"]

function isOrganizationViewMode(viewModeString: any): viewModeString is OrganizationsViewMode {
    return organizationViewModes.includes(viewModeString)
}

const facetsDistribution = ["lever", "workforce", "type", "secteur", "sous-secteur", "famille", "sous-famille", "fullLocations", "departments", "regions"];


export const SearchContextProvider = (props: SearchContextProviderProps) => {
    const queryParameters = useSearchPageQueryParameters();
    const multipleQueryParameters = useSearchPageMultipleQueryParameters()
    const [facetsDistributions, setFacetsDistributions] = useState<FacetDistribution[]>([]);
    const queryParametersFacets = useMemo(() => {
        const {page, perPage, query, viewMode, ...facets} = multipleQueryParameters
        return facets
    }, [queryParameters]);

    const [facets, setFacets] = useState(queryParametersFacets);

    useEffect(() => {
        if (!isEqual(facets, queryParametersFacets)) {
            setFacets(queryParametersFacets)
        }
    }, [queryParametersFacets, facets])

    const {
        page = defaultSearchFilters.page,
        perPage = defaultSearchFilters.perPage,
        query = defaultSearchFilters.query,
        viewMode = defaultSearchFilters.viewMode
    } = queryParameters
    const facetFilters = useMemo(() => Object
            .entries(facets)
            .map(([facetName, facetValues]) => facetValues.map(facetValue => `${facetName}:${facetValue}`))
        ,
        [facets]);
    const searchFilters = useMemo<SearchFilters>(() => {
        return {
            perPage: parseInt(perPage.toString()),
            page: parseInt(page.toString()),
            viewMode: isOrganizationViewMode(viewMode) ? viewMode : defaultSearchFilters.viewMode,
            query,
            facets
        }
    }, [perPage, page, query, facets, viewMode]);
    const searchContextActions = useSearchContextActions(searchFilters);
    const [searchResult, setSearchResult] = useState<SearchResult>({
        organizations: [],
        mapOrganizations: [],
        count: 0,
        facetsDistribution: {},
        isLoading: false
    });

    const msIndex = useMemo(() => {
        const meiliSearch = new MeiliSearch({
            host: process.env.GATSBY_MEILISEARCH_HOST,
            apiKey: process.env.GATSBY_MEILISEARCH_PUBLIC_API_KEY
        });
        return meiliSearch.index(process.env.GATSBY_MEILISEARCH_ORG_IDX_NAME) as Index<any>;
    }, []);

    const getOrganizations = useCallback(async (searchParams: SearchParams<Organization>) => {

        return await msIndex.search(
            searchFilters.query === '' ? '*' : searchFilters.query,
            searchParams
        );
    }, [searchFilters.query]);

    //first call to get facetsDistributions
    useEffect(() => {
        setSearchResult(prevState => ({
            ...prevState,
            isLoading: true
        }))
        getOrganizations({
                facetsDistribution,
            }
        ).then(response => {
            setFacetsDistributions(getFacetsDistribution(response.facetsDistribution as MeilisearchFacetsDistribution))
            setSearchResult(prevState => ({
                ...prevState,
                isLoading: false
            }))
        })
    }, [])


    //call to get list of organizations
    useEffect(() => {
        setSearchResult(prevState => ({
            ...prevState,
            isLoading: true
        }))
        getOrganizations({
                limit: searchFilters.perPage,
                offset: (searchFilters.page - 1) * searchFilters.perPage,
                facetsDistribution: facetsDistribution,
                facetFilters: facetFilters.length > 0 ? facetFilters : undefined
            }
        ).then(response => {
            // @ts-ignore
            const count = response.nbHits;
            setSearchResult(prevState => ({
                ...prevState,
                organizations: response.hits.map((hit) => {
                    return new Organization(
                        {
                            id: hit.id,
                            name: hit.name,
                            desc: hit.desc,
                            structure: hit.type,
                            effectif: hit.workforce,
                            categories: [],
                            creationYear: parseInt(hit.creationYear),
                            website: null,
                            linkedin: null,
                            tags: hit.tags,
                            levers: [],
                            headquarter: hit.headquarter,
                            headquarterCoordinates: hit.headquarterCoordinates,
                            officeCities: hit.officeCities,
                            officeCoordinates: hit.officeCoordinates,
                            fullLocations: hit.fullLocations,
                            fullCoordinates: hit.fullCoordinates
                        }
                    );
                }),
                count: count,
                facetsDistribution: response.facetsDistribution,
                isLoading: false,
            }));
        })

    }, [getOrganizations, facetFilters, searchFilters]);


    //call to get list of map organizations
    useEffect(() => {
        getOrganizations({
                facetsDistribution,
                facetFilters: facetFilters.length > 0 ? facetFilters : undefined,
                attributesToRetrieve: ["id", "name", "fullCoordinates", "fullLocations"],
                limit: 100_000
            }
        ).then(response => {
            setSearchResult(prevState => ({
                ...prevState,
                mapOrganizations: response.hits as MapOrganization[],
                isLoading: false
            }))
        })

    }, [facetFilters, getOrganizations])


    const searchContextData = useMemo<SearchContextData>(() => ({
        actions: searchContextActions,
        searchFilters,
        searchResult,
        facetsDistributions
    }), [searchContextActions, searchFilters, searchResult, facetsDistributions]);

    return (
        <SearchContext.Provider value={searchContextData}>
            {props.children}
        </SearchContext.Provider>
    );
};

export function useSearchContext(): SearchContextData {
    const searchContextData = useContext(SearchContext)
    if (searchContextData === null) {
        throw new Error("SearchContextProvider is missing in parent dom.")
    }
    return searchContextData
}
