import {useCallback, useMemo} from "react";
import {navigate, withPrefix} from "gatsby"
import {useLocation} from '@reach/router';
import {getCleanedQueryParameters} from "../components/SearchContextProvider";

const PATH_PREFIX = withPrefix("").replace("/", "");
const PATH_PREFIX_REGEXP = new RegExp(`/?${PATH_PREFIX}/?`, "gi");

function useCurrentPathName() {
    const location = useLocation()
    return useMemo(() => {
        if (PATH_PREFIX === "") {
            return location.pathname
        }
        return location.pathname.replace(PATH_PREFIX_REGEXP, "/");
    }, [location.pathname])
}


export const formatQueryParameters = (queryParameters: object) => {
    const urlSearchParameters = new URLSearchParams();
    Object.entries(queryParameters).forEach(([paramId, currentQueryParameters]) => {
        if (currentQueryParameters === undefined || currentQueryParameters === null) {
            return;
        } else if (Array.isArray(currentQueryParameters)) {
            currentQueryParameters.forEach(currentQueryParameter => {
                urlSearchParameters.append(paramId, currentQueryParameter);
            });
        } else if (typeof currentQueryParameters === "object") {
            urlSearchParameters.append(paramId, JSON.stringify(currentQueryParameters));
        } else {
            urlSearchParameters.append(paramId, currentQueryParameters);
        }
    });
    return urlSearchParameters;
};

let identityMapper = queryParameterValue => queryParameterValue;

export function useQueryParameter(name: string): string | undefined {
    return useQueryParameterWithMapper(name, identityMapper)
}

export function useQueryParameterWithMapper<T>(name: string, mapper: (queryParameterValue: string) => T): T | undefined {
    const location = useLocation();
    return useMemo(() => {
        const urlSearchParameters = new URLSearchParams(location.search)
        const queryParameterValue = urlSearchParameters.get(name);
        if (!queryParameterValue) {
            return undefined;
        }
        return mapper(queryParameterValue)
    },[name, mapper, location.search])
}


const searchPageQueryParameters = ["lever", "workforce", "type", "secteur", "sous-secteur", "famille", "sous-famille", "fullLocations",
    "page", "perPage", "facets", "query", "viewMode", "departments", "regions"]

export function useSearchPageQueryParameters() {
    return useQueryParameters(searchPageQueryParameters)
}

export function useQueryParameters(queryParametersToKeep: string[] | null = null): Record<string, string | undefined> {
    const location = useLocation();
    return useMemo(() => {
        const urlSearchParameters = new URLSearchParams(location.search);
        return [...urlSearchParameters.entries()]
            .filter(([queryParameterName]) => {
                if (!queryParametersToKeep){
                    return true
                }
                return queryParametersToKeep.includes(queryParameterName)
            })
            .reduce<Record<string, string>>((previousValue, [queryParameterName, queryParameterValue]) => {
            return ({
                ...previousValue,
                [queryParameterName]: queryParameterValue
            });
        }, {});
    }, [location.search])
}

export function useSearchPageMultipleQueryParameters(){
    return useMultipleQueryParameters(searchPageQueryParameters)
}

export function useMultipleQueryParameters(queryParametersToKeep: string[] | null = null): Record<string, string[] | undefined> {
    const location = useLocation();
    return useMemo(() => {
        const urlSearchParameters = new URLSearchParams(location.search)
        return [...urlSearchParameters.entries()]
            .filter(([queryParameterName])=> {
                if (!queryParametersToKeep){
                    return true
                }
                return queryParametersToKeep.includes(queryParameterName)
            })
            .reduce<Record<string, string[]>>((previousValue, [queryParameterName, queryParameterValue]) => {
            const previouslyDefinedQueryParameterValues = previousValue[queryParameterName];
            return ({
                ...previousValue,
                [queryParameterName]: Array.isArray(previouslyDefinedQueryParameterValues) ? [...previouslyDefinedQueryParameterValues, queryParameterValue] : [queryParameterValue]
            });
        }, {});
    }, [location.search])
}


export function useHistoryPush() {
    const currentPathName = useCurrentPathName();
    const queryParameters = useQueryParameters();
    const multipleQueryParameters = useMultipleQueryParameters();
    const historyPush = useCallback((newPathname: string, search?: object) => {
        if (search) {
            navigate(`${newPathname}?${formatQueryParameters(search)}`)
        }
        navigate(newPathname)
    }, []);

    const historyReplaceSearch = useCallback((search: object) => {
        navigate(`${currentPathName}?${formatQueryParameters(search)}`)
    }, [currentPathName]);

    const historyRemoveUniqueQueryParameter = useCallback((queryParameterName: string) => {
        const {[queryParameterName]: queryParameterToRemove, ...updatedQueryParameters} = queryParameters
        navigate(`${currentPathName}?${formatQueryParameters(updatedQueryParameters)}`)
    }, [currentPathName, queryParameters]);

    const historyRemoveMultipleQueryParameter = useCallback((queryParameterName: string, queryParameterValueToRemove: string) => {
        const {[queryParameterName]: queryParameterListToRemoveInto = [], ...rest} = multipleQueryParameters
        const updatedQueryParameters = {
            [queryParameterName]: queryParameterListToRemoveInto.filter(queryParameterValue => queryParameterValue !== queryParameterValueToRemove),
            ...rest,
            ...getCleanedQueryParameters(queryParameterName),
        }
        navigate(`${currentPathName}?${formatQueryParameters(updatedQueryParameters)}`)
    }, [currentPathName, multipleQueryParameters]);


    const historySearchAdd = useCallback((search: object) => {
        navigate(`${currentPathName}?${formatQueryParameters({...multipleQueryParameters, ...search})}`)
    }, [currentPathName, multipleQueryParameters]);

    const historySearchMultipleAdd = useCallback((queryParameterName: string, queryParameterValue: string) => {
        const {[queryParameterName]: queryParameterListToAddInto = [], ...rest} = multipleQueryParameters
        const updatedQueryParameters = {
            [queryParameterName]: [...queryParameterListToAddInto, queryParameterValue],
            ...rest,
            ...getCleanedQueryParameters(queryParameterName)
        }
        navigate(`${currentPathName}?${formatQueryParameters(updatedQueryParameters)}`)
    }, [currentPathName, multipleQueryParameters]);


    return {
        historyPush,
        historyReplaceSearch,
        historySearchAdd,
        historyRemoveUniqueQueryParameter,
        historyRemoveMultipleQueryParameter,
        historySearchMultipleAdd
    }
}

